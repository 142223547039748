.card {
  border: 1px solid #aaa;
  width: 100%;
  height: 100%;
  /* ... */
  background: url(../../public/noise.svg);
  background-color: #b5b5b5;
  filter: contrast(100%) brightness(110%);
  background-blend-mode: soft-light;
  box-shadow: rgb(50 50 93 / 20%) 0px 30px 60px -12px,
    rgb(0 0 0 / 28%) 0px 18px 36px -18px;

  padding: 0;
  position: relative;
}

.card-border {
  position: absolute;
  top: 2%;
  left: 2%;
  right: 2%;
  bottom: 2%;
  border: 3px solid rgba(255, 255, 255, 0.5);
}

@media (min-width: 0px) {
  .card-outer {
    width: 100%;
    height: 1800px;
    padding-top: 20px;
  }

  .card-inner {
    color: black;
    padding: 10%;
  }
}

@media (min-width: 900px) {
  .card-outer {
    height: 1250px;
  }

  .card-inner {
    padding: 0px 10%;
  }
}
