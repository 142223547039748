.nav-button-1 {
  background: url("../../public/nav-1.png") no-repeat left top;
  background-size: 170px 40px;
  background-position: center;
  display: inline-block;
  width: 100%;
}

.nav-button-2 {
  background: url("../../public/nav-2.png") no-repeat left top;
  background-size: 170px 40px;
  background-position: center;
  display: inline-block;
  width: 100%;
}
.nav-button-3 {
  background: url("../../public/nav-3.png") no-repeat left top;
  background-size: 170px 40px;
  background-position: center;
  display: inline-block;
  width: 100%;
}

@media (min-width: 0px) {
  .nav-outer {
    font-size: 30px;
    width: 100%;
    display: flex;
  }

  .nav-outer div {
    padding: 10px;
  }

  .nav-outer div a {
    color: white;
    font-family: "Dancing Script", cursive;
    text-decoration: none;
  }
}

@media (min-width: 900px) {
  .nav-outer {
    margin-top: 200px;
    color: pink;
    position: fixed;
    float: left;
    width: 200px;
    display: block;
  }

  .nav-outer div {
    width: 150px;
    height: 25px;
  }

  .nav-outer div a {
    width: max-content;
  }
}
