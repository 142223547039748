.purple-flowers-top {
  background: url("../../public/purple-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  right: 0px;
}

.purple-flowers-bottom {
  background: url("../../public/purple-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: scaleX(-1) scaleY(-1);
}

.purple-point {
  background: url("../../public/purple-point-3.png") no-repeat left top;
  padding-left: 60px;
  line-height: 20px;
}

.menu-content {
  text-align: left;
  font-size: smaller;
}

.menu-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@media (min-width: 0px) {
}

@media (min-width: 900px) {
}
