.App {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  line-height: 15px;
}

.heading {
  line-height: normal;
  font-family: "Dancing Script", cursive;
  font-size: 50px;
  border-bottom: 2px solid currentColor;
  width: max-content;
}

.heading-2 {
  line-height: 0px;
  font-weight: bold;
  text-decoration: underline;
  font-size: small;
  padding-top: 5px;
}

.heading-3 {
  text-decoration: underline;
}

.sakura-flowers-top {
  background: url("../public/sakura-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  right: 0px;
}

.sakura-flowers-bottom {
  background: url("../public/sakura-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: scaleX(-1) scaleY(-1);
}

@media (min-width: 0px) {
  #wrapper {
    width: 100%;
    min-width: 320px;
    margin: auto;
  }

  #inner-wrapper {
    width: 100%;
    margin: auto;
  }

  #header {
    display: none;
  }

  #mobile-header {
    display: block;
    min-height: 50px;
    width: 300px;
    background: url("../public/header.png") no-repeat center top;
    background-size: cover;
    padding-bottom: 70px;
    margin: auto;
  }
}

@media (min-width: 900px) {
  #wrapper {
    width: 1100px;
  }

  #inner-wrapper {
    width: 700px;
  }

  #mobile-header {
    display: none;
  }

  #header {
    display: block;
    height: 158px;
    width: 400px;
    margin: auto;
    background: url("../public/header.png") no-repeat center top;
    background-size: cover;
  }
}
