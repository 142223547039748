.faq-content {
  text-align: left;
  font-size: smaller;

  line-height: 20px;
}

.faq-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.peony-flowers-top {
  background: url("../../public/peony-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  right: 0px;
}

.peony-flowers-bottom {
  background: url("../../public/peony-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: scaleX(-1) scaleY(-1);
}
.faq-point {
  padding-bottom: 20px;
  background: url("../../public/peony-point.png") no-repeat left top;

  padding-left: 60px;
  background-size: 40px;
}

@media (min-width: 0px) {
}

@media (min-width: 900px) {
}
