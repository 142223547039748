.location-content {
  text-align: left;
  font-size: smaller;

  line-height: 20px;
  height: 2000px;
}

.sakura-flowers-top {
  background: url("../../public/sakura-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  right: 0px;
}

.sakura-flowers-bottom {
  background: url("../../public/sakura-flowers.png") no-repeat left top;
  height: 258px;
  width: 300px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  transform: scaleX(-1) scaleY(-1);
}

.sakura-point {
  background: url("../../public/sakura-point.png") no-repeat left top;
  padding-left: 60px;
  line-height: 20px;
}

.location-content ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.sakura-point {
  padding-bottom: 20px;
  background: url("../../public/sakura-point.png") no-repeat left top;

  padding-left: 60px;
  background-size: 40px;
}

@media (min-width: 0px) {
  #Location {
    padding-top: 70px;
  }
}

@media (min-width: 440px) {
  #Location {
    padding-top: 0px;
  }
}

@media (min-width: 900px) {
}

#zooMap {
  width: 100%;
  border: 1px solid grey;
  border-radius: 10px;
  box-shadow: rgb(50 50 93 / 20%) 0px 30px 60px -12px,
    rgb(0 0 0 / 28%) 0px 18px 36px -18px;

}

.caption {
  font-style: italic;
  font-size: small;
  text-align: center;
  margin-top: -5px;
  color: white;
}
